<template>
  <ion-segment
    scrollable
    mode="md"
    class="tabs compact no-shadow bg-transparent customize-tabs"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button
      v-for="(tab, index) of tabs"
      :key="index"
      class=""
      style="color: var(--ion-color-primary); max-width: 100%"
      :value="index"
    >
      <ion-label style="color: var(--ion-color-primary)">{{ tab.name }}</ion-label>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  tabs: { type: Array as () => Tab[], required: true },
  activeIndex: { type: Number, default: 0 },
});

const emit = defineEmits(['update:activeIndex', 'tab-changed']);

function segmentChanged($event: CustomEvent) {
  const tabIndex = $event.detail.value as number;
  const tab = props.tabs[tabIndex];

  emit('update:activeIndex', tabIndex);
  emit('tab-changed', tab);
}
</script>

<style scoped lang="sass">
.customize-tabs
  border-top-left-radius: 10px
  border-top-right-radius: 10px
  margin-bottom: 0px !important
  margin-top: 0px !important
  @media(max-width: 1024px)
   border-top-left-radius: 0px !important
   border-top-right-radius: 0px !important

.shrink
  flex: none !important

.segment-button-checked::part(native)
  background: var(--ion-color-primary)


.segment-button::part(native)
  background: #41476c !important

ion-segment-button::part(native)
  background: #41476c !important


.segment-button-checked
  *
    color: white !important
</style>
