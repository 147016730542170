<template>
  <!-- <Transition name="slide-fade">
    <div class="d-flex mobile-bottom-bar flex-column" :class="{ 'set-height': !isOpen }">
      <div class="d-flex justify-content-center text-center">
        <ion-button class="w-100 button-menu" @click="openMenu">
          <div class="d-flex">
            <ion-icon class="icon mx-2" style="margin-top: -5px" :icon="!isOpen ? chevronUp : chevronDown" />
            <span class="title">Customization Menu</span>
          </div>
        </ion-button>
      </div>
      <div class="content">
        <customize-tabs :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
        <div class="theme-content mt-3">
          <div v-if="currentTab === 'themes'">
            <themes @click="actionClicked" />
          </div>
        </div>
      </div>
    </div>
  </Transition> -->
</template>

<script lang="ts" setup>
import { chevronUp, chevronDown } from 'ionicons/icons';
import CustomizeTabs from './customize-bar/customize-tabs.vue';
import Themes from './customize-bar/themes/index.vue';
import { stylizeTabs } from '@/shared/statics/tabs';
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  templates: {
    type: Array,
    default: [],
  },
});

const isOpen = ref(true);
const tabs = ref(stylizeTabs);
const currentTab = ref('themes');
const tabIndex = ref(0);
const isSelected = ref(false);
const windowHeight = ref('0px');
const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
};
const openMenu = () => {
  isOpen.value = !isOpen.value;
};
const actionClicked = (select: any) => {
  isSelected.value = select;
};
onMounted(async () => {
  document.getElementById('scroll')?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  windowHeight.value = `${window.innerHeight}px`;
  window.addEventListener('resize', handleResize);
});

const handleResize = () => {
  windowHeight.value = `${window.innerHeight}px`;
};
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped lang="sass">
.content
  background-color: white
  margin-top: -16px !important

.set-height
  height: calc(calc(var(--safe-ios-margin) /3) + 50px) !important

.theme-content
  height: calc(v-bind(windowHeight) - 426px) !important
  overflow: auto


.button-menu
  width: 100%
  margin-left: 0px !important
  margin-right: 0px !important
  ::v-deep
    height: calc(calc(var(--safe-ios-margin) /3) + 60px) !important
    --padding-start: 0 !important
    --padding-end: 0 !important
    --padding-bottom: calc(var(--safe-ios-margin)/2)
    --padding-top: 0
    padding-left: 0 !important
    padding-right: 0 !important
    --background: linear-gradient(270deg, rgb(174 56 229) 3.33%, rgb(222 24 215) 100%) !important
    --border-radius: 15px !important

  @media(min-width: 1460px)
    display: none !important

.title
  color: white
  font-weight: bold
  font-size: 16px
  margin-top: -4px

.mobile-bottom-bar
  height: calc(100% - 320px)
  position: fixed
  bottom: 0
  width: 100%
  z-index: 112
  transition: height 1s
@media(min-width: 1024px)
  .mobile-bottom-bar
    display: none !important
</style>
