<template>
  <WelcomeThemeNote class="mx-2" />
  <br />
  <div class="character-customize-key-info position-relative mx-2">
    <CharacterMobCustomizeProfile :fontSize="'20px'" :subFontSize="'18px'" :displayContainer="true" />
  </div>
</template>
<script lang="ts" setup>
import { authStore } from '~/shared/pinia-store/auth';
import WelcomeThemeNote from './WelcomeThemeNote.vue';
import CharacterMobCustomizeProfile from '~/shared/pages/character-profile-new/character-mob-customize-profile.vue';
const windowWidth = ref(window.innerWidth);
const emits = defineEmits(['click']);
const { updateProfileTryonImage } = authStore();
const actionClicked = (select: any) => {
  emits('click', select);
};
onMounted(async () => {
  updateProfileTryonImage({});
  window.addEventListener('resize', handleResize);
});

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};
</script>

<style scoped lang="sass">

.character-customize-key-info
  border-radius: 10px
  @media(max-width:1460px)
    margin-bottom: 15px
</style>
