<template>
  <div :class="displayTitle ? 'mt-2 p-3' : 'my-3'">
    <div v-if="displayTitle" class="title">Image</div>
    <div class="d-flex justify-content-end position-relative content" :class="{ 'mx-3': setMargin }">
      <div class="position-absolute d-flex badge" v-if="featureFlags.backgroundImage">
        <SubPlanBadge v-for="(_, index) of [1, 2]" :key="index" :plan="subLevelMap[index + 1]" />
      </div>
      <ion-button
        class="round-overlay-button"
        :class="{ 'bg-button': displayTitle }"
        shape="round"
        color="dark"
        v-if="featureFlags.backgroundImage"
        @click.prevent="changeBackgroundImage"
      >
        <div class="d-flex align-items-center">
          <inline-svg class="icon mr-1" src="/icons/camera.svg" height="12px" width="12px" />
          Background Image
        </div>
      </ion-button>
      <ion-button
        class="round-overlay-button"
        :class="{ 'bg-button': displayTitle }"
        shape="round"
        color="dark"
        @click.prevent="changeBackgroundOverlay"
        v-if="featureFlags.backgroundOverlay"
      >
        <div class="d-flex align-items-center">
          <inline-svg class="icon mr-1" src="/icons/stars.svg" height="12px" width="12px" />
          Background Overlay
        </div>
      </ion-button>
    </div>

    <ProfileBackgroundOverlayModal
      :is-open="isChangingProfileBgOverlay"
      :item="virtualItem"
      :character="character"
      @save="savePrfbgOverlay"
      @close="isChangingProfileBgOverlay = false"
    />
    <ProfileBackgroundImageModal :is-open="isChangingProfileBgImage" :character="character" @close="closeModal" />
  </div>
</template>
<script lang="ts" setup>
import ProfileBackgroundOverlayModal from '@/shared/pages/profile/modals/ProfileBackgroundOverlayModal.vue';
import SubPlanBadge from '@/shared/components/SubPlanBadge.vue';
import ProfileBackgroundImageModal from '@/shared/modals/ProfileBackgroundImageModal.vue';
import constants from '~/shared/statics/constants';
import { authStore } from '~/shared/pinia-store/auth';
import { characterStylizeStore } from '~/shared/pinia-store/character-stylize';
import { Character } from '~/shared/types/static-types';
import { featureFlags } from '~/shared/config/feature-flags';
const isChangingProfileBgOverlay = ref(false);
const isChangingProfileBgImage = ref(false);
const props = defineProps({
  character: {
    type: Object as PropType<Character>,
    default: {},
  },
  displayTitle: {
    type: Boolean,
    default: false,
  },
  fontSize: {
    type: String,
    default: '22px',
  },
  setMargin: {
    type: Boolean,
    default: false,
  },
});
const { currentTemplate } = characterStylizeStore();
const emits = defineEmits(['tryOn']);
const subLevelMap = constants.subscriptionLevelsTextMap;
const savePrfbgOverlay = () => {
  isChangingProfileBgOverlay.value = false;
};
const closeModal = (boolean: any) => {
  isChangingProfileBgImage.value = false;
  if (boolean) {
    setTimeout(() => {
      emits('tryOn');
    }, 50);
  }
};

const virtualItem = computed(() => {
  return get(currentTemplate.value, 'background_overlay');
});
const changeBackgroundOverlay = () => {
  isChangingProfileBgOverlay.value = true;
};
const changeBackgroundImage = () => {
  isChangingProfileBgImage.value = true;
};
</script>
<style lang="sass" scoped>
.bg-button
  width: 50%
  @media(max-width: 355px)
    width: 100% !important

.content
  @media(max-width: 355px)
   flex-wrap: wrap
.badge
  z-index: 11
  top: -3px
  right: 196px
  @media(max-width: 1184px)
    right: 0px !important
    left: 20px !important
.title
  color: #214163
  font-size: v-bind(fontSize)
  font-weight: bold
  margin-top: -15px
  @media(max-width: 365px)
    font-size: 18px
.round-overlay-button
  --padding-start: 9px
  --padding-end: 9px
  --ion-color-dark: rgba(10, 9, 40, 0.8)
  height: 35px
  font-size: 12px
  cursor: pointer
  z-index: 4
  --ion-color-contrast: #FFF !important
</style>
