<template>
  <div class="d-flex justify-content-start customize-bar flex-column">
    <ion-button class="exit-btn mx-3" @click="exitMode">
      <ion-icon class="icon mx-2" :icon="arrowBackOutline" />
      Exit Customization Mode</ion-button
    >
    <div v-if="!isEmpty(template)" class="mt-2" :class="{ 'customize-content': !isMobSmallScreen }">
      <div style="background: #41476c; border-radius: 10px" class="px-2 py-2">
        <ion-button
          v-if="!isEmpty(template)"
          class="w-100 switch-temp"
          style="--background: #00b4c5"
          @click.prevent="openModel(true)"
        >
          <ion-icon class="icon" :icon="swapHorizontalOutline" />
          <div class="mx-1">Switch Template</div></ion-button
        >
        <themes-templates :used-temp="usedTemp" @dismiss="emits('applied')" @showtoast="getToast" />
      </div>
    </div>
    <div style="background: white; border-radius: 11px" class="mt-2">
      <div v-if="isEmpty(template)" class="px-2 py-2">
        <ion-button class="w-100 btn-bg" style="--border-radius: 20px" @click="applyDesign">
          <div>Apply</div>
        </ion-button>
      </div>
    </div>
    <stylize-character-modal
      :id="route.params.id"
      :is-open="isOpenModal"
      :is-switch="isSwitch"
      :customize="currentTemplate"
      @close="closeModal"
      @loadPage="loadCharProfile"
      @applied="appliedModal"
      @charTemp="getCharTemp"
    />

    <switching-template-design-modal
      :is-open="isSaveModal"
      :is-save="true"
      :template-name="currentTemplate.name"
      @close="closeSwitchingModal"
    />
  </div>
</template>

<script lang="ts" setup>
import { arrowBackOutline, swapHorizontalOutline } from 'ionicons/icons';
import ThemesTemplates from './customize-bar/themes/themes-templates.vue';
import StylizeCharacterModal from '@/shared/modals/StylizeCharacterModal.vue';
import SwitchingTemplateDesignModal from '@/shared/modals/SwitchingTemplateDesignModal.vue';
import { updateCharTemplate } from '@/shared/actions/characters';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { authStore } from '@/shared/pinia-store/auth';

const { customizeCharacter, currentTemplate, template, deleteAppliedCustomizationTemp } = characterStylizeStore();

const windowHeight = ref('0px');
const usedTemp = ref([]);
const isOpenModal = ref(false);
const isSaveModal = ref(false);
const counter = ref(1);
const router = useRouter();
const route = useRoute();
const emits = defineEmits(['showtoast', 'createdtoast', 'applied']);
const istoast = ref(false);
const isSwitch = ref(false);
const { updateUserTemplateReuserBtn, user: loggedInUser } = authStore();
const exitMode = () => {
  router.go(-1);
};

const closeSwitchingModal = () => {
  isSaveModal.value = false;
};
const currentCounter = computed(() => {
  const { counter } = authStore();
  return counter.value;
});
const loadCharProfile = () => {
  isOpenModal.value = false;
  getRoute();
  deleteAppliedCustomizationTemp(true);
};
const openModel = (switchVal: any) => {
  isOpenModal.value = true;
  isSwitch.value = switchVal;
};

const getRoute = () => {
  route.name === 'character-stylize'
    ? router.push({ name: 'character-profile-new', params: { slug: get(customizeCharacter.value, 'entity.slug') } })
    : router.push({
        name: 'character-profile-draft-new',
        params: { id: get(customizeCharacter.value, 'entity_id') },
      });
};

const saveTemplate = async () => {
  const payload = {
    data: currentTemplate.value?.data,
  };
  const temp_id = !isEmpty(get(currentTemplate.value, 'id'))
    ? currentTemplate.value.id
    : customizeCharacter.value?.template_applied;
  await updateCharTemplate(temp_id, true, payload);
  getRoute();
};

const getToast = () => {
  emits('applied');
  getRoute();
  istoast.value = true;
  emits('showtoast', istoast.value);
};
const getCharTemp = (temp: any) => {
  usedTemp.value = temp;
};

const applyDesign = async () => {
  const temp_id = !isEmpty(get(currentTemplate.value, 'id'))
    ? currentTemplate.value!.id
    : customizeCharacter.value?.template_applied;
  const prevSelctedTemp = template.value?.find((temp: any) => temp.id === temp_id);
  if (JSON.stringify(get(currentTemplate.value, 'data.theme')) === JSON.stringify(get(prevSelctedTemp, 'data.theme'))) {
    currentCounter.value >= 3 ? '' : (counter.value += currentCounter.value);
    updateUserTemplateReuserBtn({
      data: { tmp_quickapply: true },
      user: loggedInUser.value,
      counter: counter.value,
    });
  }
  if (isEmpty(template.value)) {
    isOpenModal.value = true;
  }
};
const closeModal = () => {
  isOpenModal.value = false;
};

const appliedModal = (value: any) => {
  closeModal();
  getRoute();
  value === 'temp' ? emits('createdtoast', (istoast.value = true)) : emits('showtoast', (istoast.value = true));
};

onMounted(async () => {
  document.getElementById('scroll')?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  windowHeight.value = `${window.innerHeight}px`;
  window.addEventListener('resize', handleResize);
});

const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 500;
});

const isMobBigScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value >= 1365;
});

const handleResize = () => {
  windowHeight.value = `${window.innerHeight}px`;
};
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped lang="sass">
.btn-bg
  --border-radius: 10px
  text-transform: none
  --background: linear-gradient(270deg, rgb(84 77 203) 3.33%, rgb(222 24 215) 100%) !important

.customize-bar
  left: 10px
  width: 327px
  position: fixed
  height: auto
  top: 12px
  @media(min-width: 1550px) and (max-width: 2000px)
    left: 58px
  @media(max-width:1365px)
    display: none !important

.customize-content
  overflow: auto
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  margin-right: 1px
  height: calc(v-bind(windowHeight) - 70px) !important

.exit-btn
  --border-radius: 20px
  --background: #5727a0
  text-transform: none
  width: 100%
  margin-left: 0px !important

.switch-temp
  --border-radius: 15px
  font-weight: 700
  height: 35px
.edit-temp
  color: black
  padding-top: 10px !important
  font-weight: bold
.dark .edit-temp
  color: black
.text
  color: black
  padding-top: 1px
.dark .text
  color: black
.icon
  font-size: 14px
.dark .edit-text
  color: black !important
</style>
