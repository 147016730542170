<template>
  <div class="try-on-bottom" :class="{ 'try-on-content': !isCustomizeProfile }">
    <div class="d-flex align-items-center justify-content-between bg-context">
      <div class="d-flex align-items-center text">
        <ion-button class="go-back-btn mr-2" v-if="!isMobSmallScreen" @click="goBack"
          ><i class="ti-arrow-left"
        /></ion-button>
        <div>
          Trying on:
          <strong class="ml-1"> Background Image</strong>
        </div>
      </div>
      <div />
      <div class="d-flex align-items-center" :class="{ 'w-100': isMobSmallScreen }">
        <ion-button class="go-back-btn mr-2" v-if="isMobSmallScreen" @click="goBack"
          ><i class="ti-arrow-left mr-2" />Exit</ion-button
        >
        <ion-button class="save-btn mr-2" @click="save" :disabled="isSaving">
          <ChLoading size="sm" v-if="isSaving" class="spinner" />
          <span v-else>Save</span> </ion-button
        ><i class="ti-close clickable-item-hov text" v-if="!isMobSmallScreen" @click="action" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { authStore } from '../pinia-store/auth';
import { characterStylizeStore } from '../pinia-store/character-stylize';
import { profileStore } from '../pinia-store/profile';

const { userTryOnBgImage, hydrateUser, updateProfileTryOnBgImage } = authStore();
const { isCustomizeProfile } = useCharPageColors();
const emits = defineEmits(['close', 'save']);
const { isMobSmallScreen } = useWindowSize();
const { postUserProfileBackgroundImage } = profileStore();
const { changeCharacterCustomizationData } = characterStylizeStore();
const isSaving = ref(false);
const goBack = () => {
  emits('close');
  updateProfileTryOnBgImage({});
};
const action = async () => {
  updateProfileTryOnBgImage({});
};

const save = async () => {
  isSaving.value = true;
  if (isCustomizeProfile.value) {
    changeCharacterCustomizationData('background_image', userTryOnBgImage.value);
  } else {
    postUserProfileBackgroundImage(userTryOnBgImage.value);
  }
  setTimeout(() => {
    updateProfileTryOnBgImage({});
    emits('save');
    isSaving.value = false;
  }, 2000);
};
</script>
<style scoped lang="sass">
.bg-context
  @media(max-width: 500px)
    flex-wrap: wrap
.save-btn
  --background: #7050B7
  --border-radius: 14px
  @media(max-width: 500px)
    width: 100%
.spinner
  height: 20px
  width: 20px
.go-back-btn
  --background: #431C89
  --border-radius: 20px
  @media(max-width: 500px)
    width: 65%
.text
  color: #431C89 !important
.try-on-bottom
  width: 40%
  background: white
  height: 58px
  padding: 12px
  z-index: 1000
  position: absolute
  bottom: 0
  left: 50%
  transform: translate(-50%, 0)
  border-top-left-radius: 12px
  border-top-right-radius: 12px
  box-shadow: 0px -7px 8px -2px rgba(35, 14, 51, 0.1)
  @media(max-width: 1034px)
    width: 100% !important
  @media(max-width: 500px)
    height: auto
.try-on-content
  @media(max-width: 1034px)
    position: relative !important
    height: auto !important
</style>
