<template>
  <CustomizeBackgroundOverlay
    v-if="get(userTryOnAnimation, 'image')"
    @close="isChangingProfileBgOverlay = true"
    @save="save"
  />
  <ProfileBackgroundOverlayModal
    :is-open="isChangingProfileBgOverlay"
    :item="virtualItem"
    :character="character"
    @save="savePrfbgOverlay"
    @close="isChangingProfileBgOverlay = false"
  />
</template>
<script lang="ts" setup>
import CustomizeBackgroundOverlay from '~/shared/components/customize-background-overlay.vue';
import { authStore } from '../pinia-store/auth';
import ProfileBackgroundOverlayModal from '@/shared/pages/profile/modals/ProfileBackgroundOverlayModal.vue';
import { characterProfileStore } from '../pinia-store/character-profile';
const { userTryOnAnimation } = authStore();
const isChangingProfileBgOverlay = ref(false);
const { character } = characterProfileStore();
const virtualItem: any = ref({});
const emits = defineEmits(['save']);

const save = async () => {
  emits('save');
};
const savePrfbgOverlay = async () => {};
</script>
<style lang="sass" scoped></style>
