<template>
  <CustomizeBackgroundImage
    v-if="get(userTryOnBgImage, 'image')"
    @close="isOpenBackgroundImageModal = true"
    @save="save"
  />
  <ProfileBackgroundImageModal
    :is-open="isOpenBackgroundImageModal"
    :character="character"
    @close="isOpenBackgroundImageModal = false"
  />
</template>
<script lang="ts" setup>
import CustomizeBackgroundImage from '~/shared/components/customize-background-image.vue';
import { authStore } from '../pinia-store/auth';
import ProfileBackgroundImageModal from '@/shared/modals/ProfileBackgroundImageModal.vue';
import { characterProfileStore } from '../pinia-store/character-profile';
const { userTryOnBgImage } = authStore();
const { character } = characterProfileStore();
const emits = defineEmits(['save']);
const isOpenBackgroundImageModal = ref(false);
const save = () => {
  emits('save');
};
</script>
<style lang="sass" scoped></style>
