<template>
  <div class="character-customize-key-info position-relative px-1 py-3">
    <div class="d-flex justify-content-center welcome-title text-center">Welcome to Themes!</div>

    <div class="d-flex align-items-center mt-2" v-for="(op, index) in options" :key="index">
      <inline-svg :src="op.icon" width="18px" height="18px" class="mx-2" />
      <div class="text">
        {{ op.text }} <strong class="mr-1">{{ op.boldText }}</strong>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const { isMobSmallScreen } = useWindowSize();

const options = ref([
  {
    icon: '/palette.svg',
    text: 'Customize Colors in',
    boldText: 'Background Colors!',
  },
  {
    icon: '/text.svg',
    text: 'Change Fonts in',
    boldText: 'Text!',
  },
  {
    icon: '/hammer-tool.svg',
    text: 'Modify Sections in ',
    boldText: 'Styles!',
  },
]);
</script>
<style lang="sass" scoped>
.character-customize-key-info
  background: #7050b7 !important
  color: white !important
.text
  font-size: 13px
.welcome-title
  font: bolder 24px "Roboto Slab", sans-serif
  -webkit-text-fill-color: white
</style>
